@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body text-primary;
  }
  .section {
    @apply py-24;
  }
  .form-control {
    @apply w-full pl-4 text-sm rounded-sm outline-none h-54 font-body text-gray focus:ring-1 focus:ring-primary;
  }
  .btn {
    @apply flex items-center justify-center w-full text-sm font-semibold tracking-wide text-white uppercase rounded-sm h-54 font-body;
  }
  .textarea {
    @apply w-full pl-4 pt-4 rounded-sm outline-none font-body text-sm text-gray focus:ring-1 focus:ring-primary min-h-[126px] resize-none;
  }
  .active {
    @apply text-accent-hover;
  }
}
